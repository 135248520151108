/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import HeadlineIcon from "../modules/HeroMedia/variants/HeadlineIcon"
import { fireEvent } from "../utils/helpers"
import FadeLoader from "react-spinners/FadeLoader"

import notfound from "../assets/images/404page.svg"
import { isSpinURL } from "../utils/spinCodes"

// markup
const NotFoundPage = () => {
  const [redirectChecked, setRedirectChecked] = useState(false)

  useEffect(() => {
    fireEvent("nav-scheme", "dark")
  }, [])

  useEffect(() => {
    let code = location.pathname.split("/")[1]

    if (isSpinURL(code)) {
      window.location.href = "https://scooters.spin.pm/qr/" + code
      return
    }

    if (code && /^([A-Z]{2}[0-9]+)$/.test(code)) {
      window.location.href = "https://qr.tier-services.io/" + code
      return
    }

    setRedirectChecked(true)
  }, [])

  return (
    <main>
      {/* Redirect Cover */}
      {!redirectChecked && (
        <div className="absolute z-[100] w-screen h-initial-height bg-green-background pointer-events-none flex justify-center items-center">
          <FadeLoader color="#0E1A50" />
        </div>
      )}

      <HeadlineIcon
        fullscreen
        content={{
          identifier: "not-found",
          lightBackground: "green-background",
          headlineIcon: [
            {
              headline: "Sorry, we couldn’t find this page.",
              overline: "404: Page not found",
              icon: [{ url: notfound }],
              buttons: [{ linkAddress: [{ text: "Go home", address: "/", actionType: "internal" }] }],
            },
          ],
        }}
      />
      <div className="bg-green-vehicle-default bg-orange-light bg-orange-background hidden" />
    </main>
  )
}

export default NotFoundPage
