import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import PalButton from "../../../components/buttons/PalButton"
import Container from "../../../components/containers/Container"

import { useReverseLanguage } from "../../../components/hooks/useReverseLanguage"
import { goTo } from "../../../utils/helpers"

const HeroContainer = styled.div`
  height: 720px;

  @media (max-width: 640px) {
    height: 640px;
  }
`

const HeadlineIcon = ({ content, fullscreen }) => {
  const { identifier, lightBackground, headlineIcon } = content
  const { headline, subline, overline, buttons, icon, alt } = headlineIcon[0]
  const backgroundColor = "bg-" + lightBackground

  return (
    <Container skipMargin id={identifier} className={"relative flex justify-center items-center " + (fullscreen ? "h-screen" : "")}>
      <div className={"absolute z-0 h-full w-screen " + backgroundColor} />
      <HeroContainer className="relative flex flex-col-reverse lg:flex-row justify-center items-center w-full" style={{ maxWidth: 934 }}>
        <div
          className={
            "relative w-full flex flex-col items-center lg:items-start lg:w-1/2 text-center " +
            (useReverseLanguage() ? "lg:text-right" : "lg:text-left")
          }
        >
          {overline && <p className="text-h5 md:text-overline font-script text-blue-informative">{overline}</p>}
          <h1 className="text-h4 md:text-h2 text-blue">{headline}</h1>
          {subline && <h1 className="text-h4 md:text-h2 text-blue">{subline}</h1>}
          {buttons && buttons.length > 0 && (
            <div className="space-y-4 xl:space-y-0 xl:gap-x-4 flex flex-col xl:flex-row w-56 mt-16">
              <PalButton
                isDownloadButton={buttons[0].linkAddress[0].isDownloadButton}
                label={buttons[0].linkAddress[0].text}
                color={buttons[0].linkAddress[0].buttonColor}
                variant={buttons[0].linkAddress[0].buttonVariant}
                action={() =>
                  goTo(buttons[0].linkAddress[0].address, buttons[0].linkAddress[0].actionType, buttons[0].linkAddress[0].openInNewWindow)
                }
              />
              {buttons[1] && (
                <PalButton
                  isDownloadButton={buttons[1].linkAddress[0].isDownloadButton}
                  label={buttons[1].linkAddress[0].text}
                  color={buttons[1].linkAddress[0].buttonColor}
                  variant={buttons[1].linkAddress[0].buttonVariant}
                  action={() =>
                    goTo(buttons[1].linkAddress[0].address, buttons[1].linkAddress[0].actionType, buttons[1].linkAddress[0].openInNewWindow)
                  }
                />
              )}
            </div>
          )}
        </div>
        <div className="h-auto w-full lg:w-1/2 relative my-12 lg:my-0 flex justify-center lg:justify-end items-center">
          <img alt={alt} src={icon[0].url} className="w-32 sm:w-44 lg:w-auto" />
        </div>
      </HeroContainer>
    </Container>
  )
}

HeadlineIcon.propTypes = {
  content: PropTypes.object.isRequired,
}

export default HeadlineIcon
